.Y-treeSelect[data-v-52f82366] .vue-treeselect__control .vue-treeselect__single-value,
.Y-treeSelect[data-v-52f82366] .vue-treeselect__value-container .vue-treeselect__single-value {
  padding-left: 10px;
  line-height: 28px;
}
.Y-treeSelect[data-v-52f82366] .vue-treeselect__control .vue-treeselect__input-container,
.Y-treeSelect[data-v-52f82366] .vue-treeselect__value-container .vue-treeselect__input-container {
  padding: 0;
}
[data-v-52f82366] .vue-treeselect__menu-container /deep/.vue-treeselect__menu {
  font-size: 14px !important;
  font-weight: 0;
}
