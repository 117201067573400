
.pop_btn[data-v-2216c3ec] {
  text-align: center;
  margin-top: 20px;
}
.popup-main[data-v-2216c3ec] {
  position: relative;
  margin: 10px auto;
  background: #fff;
  border-radius: 5px;
  font-size: 12px;
  overflow: hidden;
}
.popup-title[data-v-2216c3ec] {
  overflow: hidden;
  line-height: 34px;
  padding-top: 6px;
  background: #f2f2f2;
}
.popup-result[data-v-2216c3ec] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  line-height: 24px;
  margin: 25px auto;
  padding: 15px 10px 10px;
  border: 1px solid #ccc;
  position: relative;
}
.popup-result .title[data-v-2216c3ec] {
  position: absolute;
  top: -28px;
  left: 50%;
  width: 140px;
  font-size: 14px;
  margin-left: -70px;
  text-align: center;
  line-height: 30px;
  background: #fff;
}
.popup-result table[data-v-2216c3ec] {
  text-align: center;
  width: 100%;
  margin: 0 auto;
}
.popup-result table span[data-v-2216c3ec] {
  display: block;
  width: 100%;
  font-family: arial;
  line-height: 30px;
  height: 30px;
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid #e8e8e8;
}
.popup-result-scroll[data-v-2216c3ec] {
  font-size: 12px;
  line-height: 24px;
  height: 10em;
  overflow-y: auto;
}
