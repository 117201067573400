@charset "UTF-8";
.vue-flow-editor-icon[data-v-45d4eb98] {
  color: inherit;
  font-size: inherit;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  outline: none;
  /*阿里巴巴icon设置*/
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
