.app-breadcrumb.el-breadcrumb[data-v-b50ef614] {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 8px;
}
.app-breadcrumb.el-breadcrumb .no-redirect[data-v-b50ef614] {
    color: #97a8be;
    cursor: text;
}
