.html > > > table[data-v-004d5b90] {
  border-collapse: collapse;
}
.html > > > th[data-v-004d5b90] {
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  min-width: 50px;
  height: 20px;
}
.html > > > td[data-v-004d5b90] {
  border: 1px solid #ccc;
  min-width: 50px;
  height: 20px;
}
