@charset "UTF-8";
@font-face {
	font-family: "楷体";
	src: url(../fonts/Aa楷体.ttf) format('truetype');
}
@font-face {
    font-family: "圆体";
    src: url(../fonts/华文圆体.ttf) format('truetype');
}
@font-face {
    font-family: "诗简体";
    src: url(../fonts/汉仪全唐诗简.ttf) format('truetype');
}