@charset "UTF-8";
[data-v-b808f49e] .dialog-occupy .el-dialog {
  margin-top: 8vh !important;
}
[data-v-b808f49e] .el-tabs {
  height: initial !important;
}
.search-zone[data-v-b808f49e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.buttonbox[data-v-b808f49e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
}
.buttonbox div[data-v-b808f49e] {
    margin: 0 10px 10px 0;
}
[data-v-b808f49e] .el-tabs__content .el-collapse {
  position: relative;
  top: -60px;
  border: 0;
}
[data-v-b808f49e] .el-tabs__content .el-tab-pane {
  height: 100%;
}
[data-v-b808f49e] .el-collapse-item__header {
  border: 0;
}
[data-v-b808f49e] .el-collapse-item__wrap {
  border: 0;
}
[data-v-b808f49e] .el-table .el-table__row {
  background: #ffffff;
}
.subTotalSet[data-v-b808f49e] .el-transfer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.subTotalSet[data-v-b808f49e] .el-transfer .el-transfer__buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.subTotalSet[data-v-b808f49e] .el-transfer .el-transfer__buttons .el-transfer__button {
      margin-left: 0;
}
[data-v-b808f49e] .occupy .el-input-number .el-input__inner {
  text-align: left !important;
}
[data-v-b808f49e] .occupy .el-input-number__decrease {
  display: none !important;
}
[data-v-b808f49e] .occupy .el-input-number__increase {
  display: none !important;
}
[data-v-b808f49e] .el-table .notSubmit {
  background: #ffffff;
}
[data-v-b808f49e] .el-table .submit {
  background: #f8f8de;
}
[data-v-b808f49e] .el-table .success {
  background: #b0ffba;
}
[data-v-b808f49e] .el-table .reject {
  background: #fdb0b0;
}
[data-v-b808f49e] .el-table__body tr.current-row > td {
  background-color: #73e3f7 !important;
}
[data-v-b808f49e] .el-table .el-table__cell > .cell {
  height: 100%;
  line-height: 33px;
  text-align: center !important;
}
[data-v-b808f49e] .el-dialog__body .el-table .el-table__cell > .cell {
  height: 100%;
  line-height: 33px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
[data-v-b808f49e] #occTable th,[data-v-b808f49e] #occTable td {
  padding: 4px 0 !important;
}
[data-v-b808f49e] .el-descriptions .is-bordered {
  table-layout: auto;
}
[data-v-b808f49e] .el-descriptions__body .el-descriptions__table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}
[data-v-b808f49e] .el-descriptions .is-bordered .el-descriptions-item__cell {
  border: 1px solid #EBEEF5;
  padding: 12px 10px;
}
[data-v-b808f49e] .el-descriptions-item__label.is-bordered-label {
  font-weight: 700;
  color: #909399;
  background: #fafafa;
}
[data-v-b808f49e] .disableheadselection > .cell .el-checkbox__inner {
  display: none;
}
[data-v-b808f49e] .el-table-column--selection .cell {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 400;
  height: 100%;
}
[data-v-b808f49e] .el-tooltip {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
[data-v-b808f49e] .el-tooltip span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
[data-v-b808f49e] .cell div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  font-weight: 400;
}
[data-v-b808f49e] .el-table th,
.el-table th.is-leaf[data-v-b808f49e] {
  background: #dadbdd !important;
}
[data-v-b808f49e] .el-table--scrollable-x .el-table__body-wrapper {
  z-index: 2;
}
[data-v-b808f49e] .el-table--fluid-height .el-table__fixed,
.el-table--fluid-height .el-table__fixed-right[data-v-b808f49e] {
  background: white;
}
[data-v-b808f49e] .el-table--mini .el-table__cell {
  padding: 1px 0;
}
[data-v-b808f49e] .subtotalClass {
  font-size: 14px;
  font-weight: bold;
}
[data-v-b808f49e] ::-webkit-scrollbar {
  width: 5px !important;
  /*滚动条宽度*/
  height: 10px !important;
  /* 滚动条高度 */
}
