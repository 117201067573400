.el-input__inner[data-v-1d58a22a] {
  border: 0;
}
.container[data-v-1d58a22a] {
  padding-left: 104px;
  padding-right: 104px;
  padding: 0 52px;
  zoom: 1;
  position: relative;
  overflow: visible;
}
[data-v-1d58a22a] .el-form-item:not(:last-of-type) {
  margin-bottom: 10px;
}
[data-v-1d58a22a] .w-e-toolbar,[data-v-1d58a22a] .w-e-text-container {
  z-index: 1 !important;
}
[data-v-1d58a22a] .el-select-dropdown {
  z-index: 9999 !important;
}
