@font-face {
  font-family: "iconfont"; /* Project id 2566329 */
  src: url(../fonts/iconfont.woff2) format('woff2'),
       url(../fonts/iconfont.woff) format('woff'),
       url(../fonts/iconfont.ttf) format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-a-xinzengshangjichuangjianshangji:before {
  content: "\e87b";
}

.icon-shezhi1:before {
  content: "\e675";
}

.icon-cunrucaogaoxiang:before {
  content: "\e679";
}

.icon-tongxunluchazhao:before {
  content: "\e676";
}

.icon-icon-folder:before {
  content: "\e677";
}

.icon-touxieyoujian:before {
  content: "\e678";
}

.icon-yishanchu:before {
  content: "\e67a";
}

.icon-fajianxiang:before {
  content: "\e607";
}

.icon-shoujianxiang:before {
  content: "\e67b";
}

.icon-icon-email:before {
  content: "\e605";
}

.icon-list_cangchupeizhi:before {
  content: "\e63b";
}

.icon-list_chukudengji:before {
  content: "\e63c";
}

.icon-list_chukushenhe:before {
  content: "\e63d";
}

.icon-list_caigoujihua:before {
  content: "\e63e";
}

.icon-list_hetongguanli:before {
  content: "\e63f";
}

.icon-list_hetongshenhe:before {
  content: "\e640";
}

.icon-list_jihuashenhe:before {
  content: "\e641";
}

.icon-list_chukutongzhi:before {
  content: "\e642";
}

.icon-list_jiagong:before {
  content: "\e643";
}

.icon-list_jiagongshenhe:before {
  content: "\e644";
}

.icon-list_fukuanshenqing:before {
  content: "\e645";
}

.icon-list_jinxiangpiao:before {
  content: "\e646";
}

.icon-list_kucunfeiyong:before {
  content: "\e647";
}

.icon-list_hetongjiesuan:before {
  content: "\e648";
}

.icon-list_panku:before {
  content: "\e649";
}

.icon-list_rukudengji:before {
  content: "\e64a";
}

.icon-list_rukushenhe:before {
  content: "\e64b";
}

.icon-list_kucunchaxun:before {
  content: "\e64c";
}

.icon-list_rukutongzhi:before {
  content: "\e64d";
}

.icon-list_xiaoshoudingdan:before {
  content: "\e64e";
}

.icon-list_xiaoshouziyuan:before {
  content: "\e64f";
}

.icon-list_xiaoshoudingjia:before {
  content: "\e650";
}

.icon-list_xiaoxiangpiao:before {
  content: "\e651";
}

.icon-list_changxieshenhe:before {
  content: "\e652";
}

.icon-list_zhibaoshu:before {
  content: "\e653";
}

.icon-list_changxieguanli:before {
  content: "\e654";
}

.icon-list_zhuanku:before {
  content: "\e655";
}

.icon-list_yingshoukuan:before {
  content: "\e656";
}

.icon-list_fukuanshenhe:before {
  content: "\e657";
}

.icon-list_yingfukuan:before {
  content: "\e658";
}

.icon-list_dingdanshenhe:before {
  content: "\e659";
}

.icon-ERP_xiaoshouyuan:before {
  content: "\e65a";
}

.icon-ERP_shanchu:before {
  content: "\e65b";
}

.icon-ERP_xiadan:before {
  content: "\e65c";
}

.icon-ERP_bianji:before {
  content: "\e65d";
}

.icon-ERP_gouwuche:before {
  content: "\e65e";
}

.icon-ERP_xiazai:before {
  content: "\e65f";
}

.icon-ERP_yinhangqia:before {
  content: "\e660";
}

.icon-ERP_xuanze:before {
  content: "\e661";
}

.icon-ERP_xuanzhong:before {
  content: "\e662";
}

.icon-ERP_zhuyi:before {
  content: "\e663";
}

.icon-ERP_huokuanzongjine:before {
  content: "\e664";
}

.icon-ERP_danwei:before {
  content: "\e665";
}

.icon-ERP_xiangzuojiantou:before {
  content: "\e666";
}

.icon-ERP_xiangyoujiantou:before {
  content: "\e667";
}

.icon-ERP_kaitonggangyinjinxiaocun:before {
  content: "\e668";
}

.icon-ERP_kaitongchenggong:before {
  content: "\e669";
}

.icon-ERP_gangyinshangchenghuiyuanrenzheng:before {
  content: "\e66a";
}

.icon-list_jisuanqi:before {
  content: "\e66b";
}

.icon-ERP_shouyedangqianzhuangtai:before {
  content: "\e66c";
}

.icon-ERP_shouye:before {
  content: "\e66d";
}

.icon-ERP_daiban:before {
  content: "\e66e";
}

.icon-ERP_daibandangqianzhuangtai:before {
  content: "\e66f";
}

.icon-ERP_gongzuodangqianzhuangtai:before {
  content: "\e670";
}

.icon-ERP_gongzuo:before {
  content: "\e671";
}

.icon-list_chukubiangengshenhe:before {
  content: "\e672";
}

.icon-list_tuihuoshenqingshenhe:before {
  content: "\e673";
}

.icon-list_kucunmingxichaxun:before {
  content: "\e674";
}

.icon-tuichudenglu:before {
  content: "\e63a";
}

.icon-mubiaoguanli:before {
  content: "\e6ae";
}

.icon-zhandianshezhi:before {
  content: "\e6af";
}

.icon-renshishezhi:before {
  content: "\e6b0";
}

.icon-CRMshezhi:before {
  content: "\e6b1";
}

.icon-mingpianshezhi:before {
  content: "\e6b2";
}

.icon-wangxiaoshezhi:before {
  content: "\e6b3";
}

.icon-wodedianping:before {
  content: "\e6b4";
}

.icon-wodexuesheng:before {
  content: "\e6b5";
}

.icon-lianxijilu:before {
  content: "\e6b8";
}

.icon-tousutiwen:before {
  content: "\e6b9";
}

.icon-peixunjihua:before {
  content: "\e6ba";
}

.icon-feiyongchaxun:before {
  content: "\e6bb";
}

.icon-xueshengjiaofei:before {
  content: "\e6bc";
}

.icon-jizhang:before {
  content: "\e6bd";
}

.icon-chazhang:before {
  content: "\e6be";
}

.icon-chazhang1:before {
  content: "\e6bf";
}

.icon-baobiao:before {
  content: "\e6c0";
}

.icon-shezhi:before {
  content: "\e6c2";
}

.icon-daqiajilu:before {
  content: "\e6ce";
}

.icon-wangluobanji:before {
  content: "\e6d3";
}

.icon-wangxiaoshezhi1:before {
  content: "\e6d6";
}

.icon-xiaochengxu:before {
  content: "\e6d7";
}

.icon-houtaishezhi:before {
  content: "\e6d8";
}

.icon-zhaoshengshezhi:before {
  content: "\e6db";
}

.icon-xiaoxizhongxin:before {
  content: "\e6df";
}

.icon-zhaoshengbaobiao:before {
  content: "\e6e1";
}

.icon-caidanguanli:before {
  content: "\e6e2";
}

.icon-jituanshezhi:before {
  content: "\e6e3";
}

.icon-shichangguanli:before {
  content: "\e6e4";
}

.icon-chuangjianxitong:before {
  content: "\e6e5";
}

.icon-gongdanguanli:before {
  content: "\e6e6";
}

.icon-duanxin:before {
  content: "\e6e7";
}

.icon-bangzhuzhongxin:before {
  content: "\e6e8";
}

.icon-xueshengyidong:before {
  content: "\e6e9";
}

.icon-baokao:before {
  content: "\e6ea";
}

.icon-chengji:before {
  content: "\e6eb";
}

.icon-yuejuan:before {
  content: "\e6ec";
}

.icon-kaoheshezhi:before {
  content: "\e6ed";
}

.icon-lunwenguanli:before {
  content: "\e6ee";
}

.icon-shenbao:before {
  content: "\e6ef";
}

.icon-kecheng:before {
  content: "\e6f0";
}

.icon-tiku:before {
  content: "\e6f1";
}

.icon-jiaowushezhi:before {
  content: "\e6f2";
}

.icon-zidingyishezhi:before {
  content: "\e6f3";
}

.icon-chaojitongji:before {
  content: "\e6f4";
}

.icon-chengkaoguanli_B:before {
  content: "\e6fb";
}

.icon-chengkaoguanli_A:before {
  content: "\e6fc";
}

.icon-yuanchengguanli:before {
  content: "\e6fd";
}

.icon-shujiguanli:before {
  content: "\e6fe";
}

.icon-guochengxingkaohe:before {
  content: "\e6ff";
}

.icon-xuefenrending:before {
  content: "\e700";
}

.icon-kechengdingdan:before {
  content: "\e701";
}

.icon-wentifankui:before {
  content: "\e702";
}

.icon-zichan:before {
  content: "\e703";
}

.icon-KPIguanli:before {
  content: "\e704";
}

.icon-xiangmuguanli:before {
  content: "\e705";
}

.icon-weixingongzhonghao:before {
  content: "\e706";
}

.icon-anquanshezhi:before {
  content: "\e707";
}

.icon-fuwuzhongxin:before {
  content: "\e708";
}

.icon-qiyefuwu:before {
  content: "\e709";
}

.icon-wangkefuwu:before {
  content: "\e70a";
}

.icon-shoufeiguanli:before {
  content: "\e70b";
}

.icon-quanxianshezhi:before {
  content: "\e70c";
}

.icon-zhaoshengtongji:before {
  content: "\e70d";
}

.icon-jiaowutongji:before {
  content: "\e70e";
}

.icon-jiaoxuetongji:before {
  content: "\e70f";
}

.icon-shoufeitongji:before {
  content: "\e710";
}

.icon-xueshengtongji:before {
  content: "\e711";
}

.icon-fuwutongji:before {
  content: "\e712";
}

.icon-tongzhitongji:before {
  content: "\e713";
}

.icon-kaohetongji:before {
  content: "\e714";
}

.icon-yonghuhuoyuedu:before {
  content: "\e715";
}

.icon-xueshengbaoming:before {
  content: "\e716";
}

.icon-xueshengluqu:before {
  content: "\e717";
}

.icon-xueshengzhuce:before {
  content: "\e718";
}

.icon-zaijixuesheng:before {
  content: "\e719";
}

.icon-xuejiqingcha:before {
  content: "\e71a";
}

.icon-huamingce:before {
  content: "\e71b";
}

.icon-fenleiguanli:before {
  content: "\e71c";
}

.icon-fuwuliebiao:before {
  content: "\e71d";
}

.icon-fenleiliebiao:before {
  content: "\e71e";
}

.icon-tongzhiliebiao:before {
  content: "\e71f";
}

.icon-jiaoxuejihua:before {
  content: "\e720";
}

.icon-jiaoxueanpai:before {
  content: "\e721";
}

.icon-chengjiguanli:before {
  content: "\e722";
}

.icon-tupianguanli:before {
  content: "\e723";
}

.icon-kaoshijihua:before {
  content: "\e724";
}

.icon-kaoshipeizhi:before {
  content: "\e725";
}

.icon-xuexizhongxinguanli:before {
  content: "\e726";
}

.icon-kaoheshezhi1:before {
  content: "\e727";
}

.icon-kaoheguanli:before {
  content: "\e728";
}

.icon-jiaoshiguanli:before {
  content: "\e729";
}

.icon-wangkeguanli:before {
  content: "\e72a";
}

.icon-bulujiesuan:before {
  content: "\e72b";
}

.icon-biyeguanli:before {
  content: "\e72c";
}

.icon-kaohezhibiao:before {
  content: "\e72d";
}

.icon-qiyeguanli:before {
  content: "\e77e";
}

.icon-chubuguanli:before {
  content: "\e77f";
}

.icon-hujiaobaobiao:before {
  content: "\e780";
}

.icon-saomiaodianjing:before {
  content: "\e781";
}

.icon-jiezhang:before {
  content: "\e782";
}

.icon-xiaoshoubaobiao:before {
  content: "\e783";
}

.icon-pingzheng:before {
  content: "\e784";
}

.icon-hetongyidong:before {
  content: "\e785";
}

.icon-shenpishezhi1:before {
  content: "\e786";
}

.icon-xiaoshoupin:before {
  content: "\e787";
}

.icon-xueshuguanli:before {
  content: "\e788";
}

.icon-yihaopin:before {
  content: "\e789";
}

.icon-renwuguanli:before {
  content: "\e78a";
}

.icon-dianshang:before {
  content: "\e78b";
}

.icon-mingpianbaobiao_1:before {
  content: "\e78c";
}

.icon-gudingzichan:before {
  content: "\e78d";
}

.icon-dongtaiguanli:before {
  content: "\e78e";
}

.icon-jichupeizhi:before {
  content: "\e78f";
}

.icon-hujiaotongji:before {
  content: "\e790";
}

.icon-xiaoshoubaobiao_1:before {
  content: "\e791";
}

.icon-dashuju:before {
  content: "\e792";
}

.icon-huodongguanli:before {
  content: "\e793";
}

.icon-hujiaoshezhi:before {
  content: "\e794";
}

.icon-fenxiaoshichang:before {
  content: "\e795";
}

.icon-chengjitongji:before {
  content: "\e797";
}

.icon-tuiguangshezhi:before {
  content: "\e798";
}

.icon-kechengzhongxin:before {
  content: "\e799";
}

.icon-hetongshezhi:before {
  content: "\e79a";
}

.icon-kechengbaoguanli:before {
  content: "\e79b";
}

.icon-yonghuguanli:before {
  content: "\e79c";
}

.icon-yonghuhuaxiang:before {
  content: "\e79d";
}

.icon-zhanghaoguanli:before {
  content: "\e79e";
}

.icon-SEMguanli:before {
  content: "\e79f";
}

.icon-yusuan:before {
  content: "\e7a0";
}

.icon-mingpianbaobiao:before {
  content: "\e7a1";
}

.icon-dingdanguanli:before {
  content: "\e7a2";
}

.icon-tuiguangguanli:before {
  content: "\e7a3";
}

.icon-zhuantiguanli:before {
  content: "\e7a4";
}

.icon-xinxiliu:before {
  content: "\e7a5";
}

.icon-xiaoshoushezhi:before {
  content: "\e7a6";
}

.icon-sucaiguanli:before {
  content: "\e7a9";
}

.icon-xueshengdaoru:before {
  content: "\e7aa";
}

.icon-shangpinguanli:before {
  content: "\e7ab";
}

.icon-paikeguanli:before {
  content: "\e7ac";
}

.icon-xinxidaoru:before {
  content: "\e7ad";
}

.icon-shoufeipeizhi:before {
  content: "\e7ae";
}

.icon-yonghuquanxian:before {
  content: "\e7af";
}

.icon-zaijixueshengguanli:before {
  content: "\e7b0";
}

.icon-xuehaoguanli:before {
  content: "\e7b1";
}

.icon-kechengziyuanguanli:before {
  content: "\e7b2";
}

.icon-mianshoujiaoxueanpai:before {
  content: "\e7b3";
}

.icon-zhangfang:before {
  content: "\e7b4";
}

.icon-kaikeguanli:before {
  content: "\e7b5";
}

.icon-biyetongji:before {
  content: "\e7b6";
}

.icon-renwuguanli1:before {
  content: "\e7b7";
}

.icon-zhaoshengzhunbei:before {
  content: "\e7b8";
}

.icon-wangluojiaoxueanpai:before {
  content: "\e7b9";
}

.icon-paikeshezhi:before {
  content: "\e7ba";
}

.icon-banjiguanli:before {
  content: "\e7bb";
}

.icon-xuejizhuangtaiyidong:before {
  content: "\e7bc";
}

.icon-jingpinketang:before {
  content: "\e7bd";
}

.icon-yuangongtidian:before {
  content: "\e7be";
}

.icon-kaoshianpai:before {
  content: "\e7bf";
}

.icon-zhangfang1:before {
  content: "\e7c0";
}

.icon-xinxidaoru1:before {
  content: "\e7c1";
}

.icon-yuangongtidian1:before {
  content: "\e7c2";
}

.icon-xuejizhuangtaiyidong1:before {
  content: "\e7c3";
}

.icon-xuejizhuangtaiyidong2:before {
  content: "\e7c4";
}

.icon-xuehaoguanli1:before {
  content: "\e7c5";
}

.icon-banjiguanli1:before {
  content: "\e7c6";
}

.icon-paikeguanli1:before {
  content: "\e7c7";
}

.icon-yonghuquanxian1:before {
  content: "\e7c8";
}

.icon-paikeshezhi1:before {
  content: "\e7c9";
}

.icon-kechengziyuanguanli1:before {
  content: "\e7ca";
}

.icon-zaijixueshengguanli1:before {
  content: "\e7cb";
}

.icon-biyetongji1:before {
  content: "\e7cc";
}

.icon-mianshoujiaoxueanpai1:before {
  content: "\e7cd";
}

.icon-wangluojiaoxueanpai1:before {
  content: "\e7ce";
}

.icon-kaoshianpai1:before {
  content: "\e7cf";
}

.icon-kaikeguanli1:before {
  content: "\e7d0";
}

.icon-jingpinketang1:before {
  content: "\e7d1";
}

.icon-zhaoshengzhunbei1:before {
  content: "\e7d2";
}

.icon-ruxuechengji:before {
  content: "\e7d3";
}

.icon-wangluojiaoxue:before {
  content: "\e7d4";
}

.icon-mianshoujiaoxue:before {
  content: "\e7d5";
}

.icon-jiaoshijiaoshiguanli:before {
  content: "\e7d6";
}

.icon-luquguanli:before {
  content: "\e7d7";
}

.icon-luquchengji:before {
  content: "\e7d8";
}

.icon-ziyuan:before {
  content: "\e7d9";
}

.icon-ziyuan1:before {
  content: "\e7da";
}

.icon-kejian:before {
  content: "\e7db";
}

.icon-waibukejian:before {
  content: "\e7dc";
}

.icon-rizhiguanli:before {
  content: "\e7dd";
}

.icon-mobanguanli:before {
  content: "\e7de";
}

.icon-xuejiyidong:before {
  content: "\e7df";
}

.icon-xuelirenzheng:before {
  content: "\e7e0";
}

.icon-yiyichuli:before {
  content: "\e7e1";
}

.icon-lunwenshenbao:before {
  content: "\e7e2";
}

.icon-lunwenshezhi:before {
  content: "\e7e3";
}

.icon-jiaoxuedianshezhi:before {
  content: "\e7e4";
}

.icon-buluchenjiang:before {
  content: "\e7e5";
}

.icon-mokuaishezhi:before {
  content: "\e7e6";
}

.icon-ziliaoguanli:before {
  content: "\e7e7";
}

.icon-jituanguanli:before {
  content: "\e7e8";
}

.icon-yingyongguanli:before {
  content: "\e7e9";
}

.icon-guanwangshijuan:before {
  content: "\e7ea";
}

.icon-wodewangke:before {
  content: "\e7eb";
}

.icon-jiaobenguanli:before {
  content: "\e7ec";
}

.icon-zhuanyeguanliyuan:before {
  content: "\e7ed";
}

.icon-xueshengchengji:before {
  content: "\e7ee";
}

.icon-xueweijihua:before {
  content: "\e7ef";
}

.icon-xueshixueweishenqing:before {
  content: "\e7f0";
}

.icon-fuwuguanli:before {
  content: "\e7f1";
}

.icon-daijinquan:before {
  content: "\e7f2";
}

.icon-hujiaozhongxin:before {
  content: "\e7f3";
}

.icon-yinjinshoufei:before {
  content: "\e7f4";
}

.icon-yingjiaoqingdan:before {
  content: "\e7f5";
}

.icon-duizhangdan:before {
  content: "\e7f6";
}

.icon-shangjiguanli:before {
  content: "\e7f7";
}

.icon-qiyehuihuacundang:before {
  content: "\e7f8";
}

.icon-yonghushezhi:before {
  content: "\e7f9";
}

.icon-dianpuzhuangxiu:before {
  content: "\e7fa";
}

.icon-xiaoxishezhi:before {
  content: "\e7fb";
}

.icon-yingxiaoshezhi:before {
  content: "\e7fc";
}

.icon-fuwushichang:before {
  content: "\e7fd";
}

.icon-sucaiziyuan:before {
  content: "\e7fe";
}

.icon-yingxiaowanfa:before {
  content: "\e7ff";
}

.icon-fl-renshi:before {
  content: "\e638";
}

.icon-tuiguang:before {
  content: "\e800";
}

.icon-wenjianjia:before {
  content: "\ec17";
}

.icon-yonghuliebiao:before {
  content: "\e801";
}

.icon-dianboxindiantu:before {
  content: "\e639";
}

.icon-wenjianjia2:before {
  content: "\e796";
}

.icon-xueyuanguanli:before {
  content: "\e691";
}

.icon-ziyuanguanli:before {
  content: "\e692";
}

.icon-kechengguanli:before {
  content: "\e693";
}

.icon-kechengbao:before {
  content: "\e694";
}

.icon-tikuguanli:before {
  content: "\e695";
}

.icon-wangkeshichang:before {
  content: "\e696";
}

.icon-gongdan:before {
  content: "\e697";
}

.icon-fangkeguanli:before {
  content: "\e698";
}

.icon-xiansuoguanli:before {
  content: "\e699";
}

.icon-kehuguanli:before {
  content: "\e69a";
}

.icon-jituankehu:before {
  content: "\e69b";
}

.icon-genjinjilu:before {
  content: "\e69c";
}

.icon-hujiaoguanli:before {
  content: "\e69d";
}

.icon-gongzuotai:before {
  content: "\e69e";
}

.icon-shujubaobiao:before {
  content: "\e69f";
}

.icon-fangkebaobiao:before {
  content: "\e6a0";
}

.icon-zhangbuguanli:before {
  content: "\e6a1";
}

.icon-zhangbuguanli_zi:before {
  content: "\e6a2";
}

.icon-zhangbuguanli_zixitong_A:before {
  content: "\e6a3";
}

.icon-hetongguanli:before {
  content: "\e6a4";
}

.icon-yunyingzheguanli:before {
  content: "\e6a5";
}

.icon-zhaopinguanli:before {
  content: "\e6a6";
}

.icon-gongziguanli:before {
  content: "\e6a7";
}

.icon-wodeshenpi:before {
  content: "\e6a8";
}

.icon-biaozhunmobanshezhi:before {
  content: "\e6a9";
}

.icon-jichushezhi:before {
  content: "\e6aa";
}

.icon-caiwushezhi:before {
  content: "\e6ab";
}

.icon-chanpinshezhi:before {
  content: "\e6ac";
}

.icon-shenpishezhi:before {
  content: "\e6ad";
}

.icon-gouzhijihua:before {
  content: "\e602";
}

.icon-xuanxingjihua:before {
  content: "\e603";
}

.icon-gouzhijindu:before {
  content: "\e604";
}

.icon-baoyangguanli:before {
  content: "\e606";
}

.icon-weihuguanli:before {
  content: "\e608";
}

.icon-yunhangguanli:before {
  content: "\e609";
}

.icon-gengxinjihua:before {
  content: "\e60a";
}

.icon-ancheguanli-copy:before {
  content: "\e60b";
}

.icon-anzhuangguanli:before {
  content: "\e60c";
}

.icon-tiaobotiaoji:before {
  content: "\e60d";
}

.icon-gengxinjihua1:before {
  content: "\e60e";
}

.icon-baofeiguanli:before {
  content: "\e60f";
}

.icon-zulinguanli:before {
  content: "\e610";
}

.icon-xiuliguanli:before {
  content: "\e611";
}

.icon-shenpiguanli:before {
  content: "\e612";
}

.icon-cangkuguanli:before {
  content: "\e613";
}

.icon-baobiaoguanli:before {
  content: "\e614";
}

.icon-kanbanguanli:before {
  content: "\e615";
}

.icon-biaoqianguanli:before {
  content: "\e616";
}

.icon-shebeiguanli:before {
  content: "\e617";
}

.icon-shebeijiaojie:before {
  content: "\e618";
}

.icon-xitongshu:before {
  content: "\e619";
}

.icon-zulintaizhang:before {
  content: "\e61a";
}

.icon-zulinxieyi:before {
  content: "\e61b";
}

.icon-zulinfei:before {
  content: "\e61c";
}

.icon-gongzuomianpeitao:before {
  content: "\e61d";
}

.icon-xiulijilu:before {
  content: "\e61e";
}

.icon-xiulijianding:before {
  content: "\e61f";
}

.icon-xiulijihua:before {
  content: "\e620";
}

.icon-xiuliyanshou:before {
  content: "\e621";
}

.icon-chukuguanli:before {
  content: "\e622";
}

.icon-rukuguanli:before {
  content: "\e623";
}

.icon-pandianguanli:before {
  content: "\e624";
}

.icon-kuweiguanli:before {
  content: "\e625";
}

.icon-jichuziliao:before {
  content: "\e626";
}

.icon-yunhangziliao:before {
  content: "\e627";
}

.icon-anzhuangziliao:before {
  content: "\e628";
}

.icon-caozuoshouce:before {
  content: "\e629";
}

.icon-gouzhijihuabaobiao:before {
  content: "\e62a";
}

.icon-xitongtongjifenxibaobiao:before {
  content: "\e62b";
}

.icon-zhengmingziliaoshuomingshu:before {
  content: "\e62c";
}

.icon-jishucanshu:before {
  content: "\e62d";
}

.icon-anquanjishucuoshi:before {
  content: "\e62e";
}

.icon-jishufangan:before {
  content: "\e62f";
}

.icon-jungongyanshou:before {
  content: "\e630";
}

.icon-anzhuangjilu:before {
  content: "\e631";
}

.icon-tiaoshijilu:before {
  content: "\e632";
}

.icon-baoyangjilu:before {
  content: "\e633";
}

.icon-weihujilu:before {
  content: "\e634";
}

.icon-guzhangjilu:before {
  content: "\e635";
}

.icon-yunhangjilu:before {
  content: "\e636";
}

.icon-tongjijilu:before {
  content: "\e637";
}

