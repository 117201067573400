.editor {
  width: 100%;
  height: 100%;
  margin: auto;
  position: relative;
  z-index: 10;
}
.textareaInput {
  height: calc(50vh - 20px);
}
.textareaInput textarea {
    height: 100%;
}
