[data-v-6b5dcbfb] .el-dialog__footer {
  position: sticky !important;
  bottom: 0;
  right: 0;
  background: #ffffff;
  width: 100%;
  z-index: 100;
}
[data-v-6b5dcbfb] .el-dialog__header {
  position: sticky !important;
  top: 0;
  left: 0;
  background: #ffffff;
  width: 100%;
  z-index: 100;
}
