.inputs[data-v-74165d6f] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
[data-v-74165d6f] .el-input {
  width: 160px;
}
[data-v-74165d6f] .el-select {
  width: 160px;
}
[data-v-74165d6f] .el-input-number--mini {
  width: 160px;
}
