.tags-bar[data-v-35ab51d4] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: hidden;
  background-color: var(--barColor);
}
.tags-bar .scrollTag[data-v-35ab51d4] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    overflow: hidden;
}
.tags-bar .scrollTag .tags[data-v-35ab51d4] {
      display: inline-block;
      white-space: nowrap;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
}
.tags-bar .scrollTag .tags .tags-item[data-v-35ab51d4] {
        display: inline-block;
        font-size: 14px;
        padding: 8px 0;
        color: var(--barColor-font);
        border-radius: 5px 5px 0 0;
        position: relative;
}
.tags-bar .scrollTag .tags .tags-item .tag-item[data-v-35ab51d4] {
          display: inline-block;
          padding: 0 14px;
          -webkit-box-sizing: border-box;
                  box-sizing: border-box;
}
.tags-bar .scrollTag .tags .tags-item .tag-item[data-v-35ab51d4]:last-child {
            border-right: none;
}
.tags-bar .scrollTag .tags .tags-item .el-icon-close[data-v-35ab51d4] {
          margin-left: 6px;
          color: var(--barColor-font);
}
.tags-bar .scrollTag .tags .tags-item .el-icon-close[data-v-35ab51d4] {
          color: var(--barColor-font);
          width: 14px;
          height: 14px;
          border-radius: 50%;
          text-align: center;
          line-height: 14px;
}
.tags-bar .scrollTag .tags .tags-item .el-icon-close[data-v-35ab51d4]:hover {
            color: var(--barColor-dark-2);
            background: var(--barColor-font-active);
}
.tags-bar .scrollTag .tags .tags-item[data-v-35ab51d4]:hover {
          color: var(--barColor-font-active);
          background: var(--barColor-dark-2);
}
.tags-bar .scrollTag .tags .active[data-v-35ab51d4] {
        color: var(--barColor-font-active);
        background: var(--barColor-dark-1);
}
.tags-bar .btns_nav[data-v-35ab51d4] {
    text-align: center;
    color: var(--barColor-font);
    overflow: hidden;
    font-size: 20px;
}
.tags-bar .btns_nav .el-icon-arrow-left[data-v-35ab51d4] {
      padding: 0 10px;
}
.tags-bar .btns_nav .el-icon-arrow-right[data-v-35ab51d4] {
      padding: 0 10px;
      border-right: 1px solid var(--barColor-font);
}
.tags-bar .btns_nav[data-v-35ab51d4]:hover {
      color: var(--barColor-font-active);
      cursor: pointer;
}
