.annexes[data-v-38b47805] {
  padding: 50px 80px;
}
.annexes .upload-demo[data-v-38b47805] {
    width: 360px;
}
.annexes .upload-demo .el-upload__text[data-v-38b47805],
    .annexes .upload-demo .el-upload__tip[data-v-38b47805] {
      font-size: 18px;
}
.annexes .upload-demo .el-upload__tip[data-v-38b47805] {
      text-align: center;
}
