.Examination[data-v-5397c198] {
  width: 100%;
  height: calc(100% - 120px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.Examination .leftBox[data-v-5397c198] {
    width: 45%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.Examination .leftBox .topBox[data-v-5397c198] {
      width: 100%;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
}
.Examination .leftBox .topBox .topsunbox[data-v-5397c198] {
        margin-right: 15px;
        margin-bottom: 10px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
}
.Examination .leftBox .topBox .topsunbox span[data-v-5397c198] {
          margin-right: 5px;
          display: block;
          width: 80px;
}
.Examination .leftBox .row-bg[data-v-5397c198] {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.Examination .leftBox .bottomBox[data-v-5397c198] {
      width: 100%;
      height: 60%;
      padding: 10px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
}
.Examination .leftBox .bottomBox img[data-v-5397c198] {
        width: 100%;
        height: 100%;
        -o-object-fit: contain;
           object-fit: contain;
}
.Examination .dialogImg[data-v-5397c198] {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
}
.Examination[data-v-5397c198] .el-select {
    width: 160px;
}
.Examination .rightBox[data-v-5397c198] {
    width: 55%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.Examination .rightBox .h1span[data-v-5397c198] {
      width: 100%;
      height: 60px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
}
.Examination .rightBox .actionBox[data-v-5397c198] {
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1;
      height: 100%;
      padding: 10px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
}
[data-v-5397c198] .el-table th.el-table__cell {
  background: #dddddd;
}
