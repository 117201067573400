[data-v-96a3565e] .el-tree-node__content {
  height: 35px;
}
[data-v-96a3565e] .el-input-group__append {
  padding: 0 10px;
}
[data-v-96a3565e] .el-tree-node {
  overflow: auto;
  overflow-y: hidden;
}
