.splitpanes.default-theme .splitpanes__pane[data-v-35cb88d6] {
  background-color: initial;
}
.circleRed[data-v-35cb88d6] {
  display: block;
  width: 8px;
  height: 8px;
  background: red;
  border-radius: 50%;
  margin-right: 7px;
}
.circleBlue[data-v-35cb88d6] {
  display: block;
  width: 8px;
  height: 8px;
  background: blue;
  border-radius: 50%;
  margin-right: 7px;
}
.mail-zone[data-v-35cb88d6] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.mail-zone .mail-list[data-v-35cb88d6] {
    padding: 10px;
    height: 100%;
}
.mail-zone .mail-list .tab-bar[data-v-35cb88d6] {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      margin-bottom: 10px;
}
.mail-zone .mail-list .tab-bar span[data-v-35cb88d6] {
        font-size: 14px !important;
        color: #848994;
}
.mail-zone .mail-list .mail-item[data-v-35cb88d6] {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      font-size: 12px !important;
}
.mail-zone .mail-list .mail-item .mail-item-info[data-v-35cb88d6],
      .mail-zone .mail-list .mail-item .mail-item-date[data-v-35cb88d6] {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
}
.mail-zone .mail-list .mail-item .mail-item-info span[data-v-35cb88d6]:nth-of-type(1),
        .mail-zone .mail-list .mail-item .mail-item-date span[data-v-35cb88d6]:nth-of-type(1) {
          padding-right: 5px;
}
.mail-zone .mail-list .mail-item .mail-item-info[data-v-35cb88d6] {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 65%;
                flex: 0 0 65%;
}
.mail-zone .mail-list .mail-item .mail-item-info span[data-v-35cb88d6]:first-of-type {
          color: #848994;
}
.mail-zone .mail-list .mail-item .mail-item-info span[data-v-35cb88d6]:last-of-type {
          color: #333;
}
.mail-zone .mail-list .mail-item .mail-item-date[data-v-35cb88d6] {
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1;
}
.mail-zone .mail-list .mail-item .mail-item-date span[data-v-35cb88d6] {
          text-align: right;
}
.mail-zone .mail-list .mail-item .mail-item-date span[data-v-35cb88d6]:first-of-type {
            color: #848994;
}
.mail-zone .mail-list .mail-item .mail-item-date span .del[data-v-35cb88d6] {
            text-align: right;
            padding-right: 5px;
            font-size: 14px;
            color: violet;
            cursor: pointer;
}
.mail-zone .mail-list .pagination[data-v-35cb88d6] {
      text-align: center;
      margin-top: 10px;
      margin-bottom: 5px;
}
.mail-zone .mail-details[data-v-35cb88d6] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    height: 100%;
    padding-left: 20px;
}
.mail-zone .mail-details .txt[data-v-35cb88d6] {
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
}
.mail-zone .mail-details .txt .info[data-v-35cb88d6] {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        border-bottom: 1px solid lightgray;
        margin-bottom: 10px;
}
.mail-zone .mail-details .txt .info span[data-v-35cb88d6] {
          color: #999;
          margin-bottom: 5px;
}
.mail-zone .mail-details .txt .info span[data-v-35cb88d6]:first-of-type {
            color: #333;
}
.mail-zone .mail-details .txt .info .replyBtn[data-v-35cb88d6] {
          width: 80px;
          margin-bottom: 10px;
}
.mail-zone .mail-details .txt .detail[data-v-35cb88d6] {
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1;
        overflow-y: auto;
}
[data-v-35cb88d6] .el-table th.el-table__cell > .cell {
  padding-left: 14px;
}
[data-v-35cb88d6] .el-table {
  max-height: 640px;
  overflow-y: auto;
}
[data-v-35cb88d6] .el-button + .el-button {
  margin-left: 0;
}
[data-v-35cb88d6]::-webkit-scrollbar {
  height: 9px;
  width: 9px;
}
[data-v-35cb88d6]::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border-style: dashed;
  border-color: transparent;
  border-width: 2px;
  background-color: rgba(157, 165, 183, 0.4);
  background-clip: padding-box;
}
[data-v-35cb88d6]::-webkit-scrollbar-thumb:hover {
  background: rgba(157, 165, 183, 0.7);
}
