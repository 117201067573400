[class*=" el-icon-"][data-v-5351c243], [class^=el-icon-][data-v-5351c243] {
  width: 30px;
  height: 3px;
  font-size: 22px;
}
[data-v-5351c243] .el-table td, .el-table th[data-v-5351c243] {
  padding: 8px 4px;
}
.el-icon-delete-solid[data-v-5351c243] {
  color: red;
}
.el-icon-download[data-v-5351c243] {
  color: cornflowerblue;
}
