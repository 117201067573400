











































































































































































































































/* .w-e-text-container{
  z-index: 2000  !important;
}
.w-e-toolbar{
   z-index: 2000  !important;
} */
.attachOperate {
  height: 40px;
  white-space: nowrap;
  position: relative;
}
.fujian {
  height: 31px;
  overflow: hidden;
  position: absolute;
  cursor: pointer;
  left: -4px;
}
.cb0 {
  margin-left: 14px;
  float: left;
  display: block;
  height: 22px;
  line-height: 12px;
}
.nui-ico-cmpAttach {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALQAAABGAgMAAAAY+VazAAAAA3NCSVQICAjb4U/gAAAACVBMVEX///////96enqykic0AAAAA3RSTlMA//9EUNYhAAAACXBIWXMAAArrAAAK6wGCiw1aAAAAFnRFWHRDcmVhdGlvbiBUaW1lADExLzIxLzEzNm64tAAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNXG14zYAAABLSURBVEiJY+BqYIAAVgcGwkBBAcoQECBCdRMHlOHIQoTqjg4ow8VlVDVZqhtgqh2IUa0FY4gSoXgUjIJRMApGwSgYBaNgFIwCWgEAHAIO8o6JYwoAAAAASUVORK5CYII=)
    no-repeat 0 0;
  width: 7px;
  height: 12px;
  overflow: hidden;
  position: absolute;
  left: 5px;
}
.fitem {
  width: 320px;
  background-color: #efefef;
  height: 44px;
  float: left;
  position: relative;
  margin: 0 8px 8px 0;
  border-radius: 3px;
  overflow: visible;
  font-size: 12px;
}
.nui-ico-file {
  width: 32px;
  height: 32px;
}
.o0 {
  width: 200px;
  height: 28px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #666;
  position: absolute;
  left: 41px;
  top: -4px;
}
.m0 {
  position: absolute;
  right: 6px;
  top: 5px;
  font-size: 12px;
}
.nui-txt-link {
  outline: 0;
  cursor: pointer;
  padding: 2px 4px 4px;
  padding: 3px 4px;
  text-decoration: none;
  border-radius: 3px;
}
.j0 {
  height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #b0b0b0;
  position: absolute;
  left: 41px;
  top: 21px;
  line-height: 160%;
  overflow: visible;
  font-size: 12px;
}
.nui-txt-suc {
  color: #3d882d !important;
}

