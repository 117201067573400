.vue-flow-editor {
  height: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  overflow: hidden; }
  .vue-flow-editor .vue-flow-editor-left {
    overflow: hidden;
    -webkit-box-shadow: 0 2px 8px #f0f1f2;
            box-shadow: 0 2px 8px #f0f1f2; }
  .vue-flow-editor .vue-flow-editor-right {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    overflow: hidden; }
  .vue-flow-editor .vue-flow-editor-menu {
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    .vue-flow-editor .vue-flow-editor-menu .vue-flow-editor-menu-header {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      letter-spacing: 2px;
      color: black;
      -webkit-box-shadow: 0 2px 8px #f0f1f2;
              box-shadow: 0 2px 8px #f0f1f2;
      -webkit-box-sizing: border-box;
              box-sizing: border-box; }
      .vue-flow-editor .vue-flow-editor-menu .vue-flow-editor-menu-header img {
        height: 100%; }
    .vue-flow-editor .vue-flow-editor-menu .vue-flow-editor-menu-list {
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1;
      overflow: hidden;
      background-color: #f9f9f9;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }
      .vue-flow-editor .vue-flow-editor-menu .vue-flow-editor-menu-list .vue-flow-editor-menu-list-content {
        height: 100%;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden; }
      .vue-flow-editor .vue-flow-editor-menu .vue-flow-editor-menu-list .vue-flow-edit-menu-group .vue-flow-edit-menu-group-title {
        font-size: 14px;
        font-weight: 500;
        color: #777;
        background-color: white;
        padding: 0 16px;
        height: 40px;
        margin-top: 2px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        cursor: pointer; }
      .vue-flow-editor .vue-flow-editor-menu .vue-flow-editor-menu-list .vue-flow-edit-menu-group .vue-flow-edit-menu-group-content {
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        padding: 6px; }
      .vue-flow-editor .vue-flow-editor-menu .vue-flow-editor-menu-list .vue-flow-edit-menu-group.vue-flow-edit-menu-group-expanded .vue-flow-edit-menu-group-title i {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg); }
      .vue-flow-editor .vue-flow-editor-menu .vue-flow-editor-menu-list .vue-flow-edit-menu {
        padding: 9px 16px;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        background-color: white;
        margin-bottom: 2px;
        cursor: move;
        font-size: 14px;
        color: #777;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-transition: all 300ms linear;
        transition: all 300ms linear; }
        .vue-flow-editor .vue-flow-editor-menu .vue-flow-editor-menu-list .vue-flow-edit-menu:hover {
          background-color: rgba(31, 116, 255, 0.08);
          color: black; }
        .vue-flow-editor .vue-flow-editor-menu .vue-flow-editor-menu-list .vue-flow-edit-menu:active {
          background-color: rgba(31, 116, 255, 0.08); }
        .vue-flow-editor .vue-flow-editor-menu .vue-flow-editor-menu-list .vue-flow-edit-menu:first-child {
          margin-top: 2px; }
  .vue-flow-editor .vue-flow-editor-toolbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    padding: 0 16px;
    -webkit-box-shadow: 0 2px 8px #f0f1f2;
            box-shadow: 0 2px 8px #f0f1f2;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    font-size: 14px; }
    .vue-flow-editor .vue-flow-editor-toolbar > * {
      cursor: pointer;
      color: #777; }
    .vue-flow-editor .vue-flow-editor-toolbar .vue-flow-editor-toolbar-item {
      width: 60px;
      height: 48px;
      outline: none;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
      .vue-flow-editor .vue-flow-editor-toolbar .vue-flow-editor-toolbar-item img {
        width: 16px;
        height: 16px;
        margin-bottom: 4px; }
      .vue-flow-editor .vue-flow-editor-toolbar .vue-flow-editor-toolbar-item span {
        font-size: 12px;
        -webkit-transform: scale(0.8);
                transform: scale(0.8); }
      .vue-flow-editor .vue-flow-editor-toolbar .vue-flow-editor-toolbar-item:hover {
        background-color: #f6f6f6;
        border-radius: 2px; }
      .vue-flow-editor .vue-flow-editor-toolbar .vue-flow-editor-toolbar-item.vue-flow-editor-toolbar-item-disabled {
        opacity: 0.5;
        background-color: transparent;
        cursor: not-allowed; }
    .vue-flow-editor .vue-flow-editor-toolbar .vue-flow-editor-toolbar-divider {
      height: 18px;
      border-left: solid 1px #ddd; }
  .vue-flow-editor .vue-flow-editor-canvas {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    overflow: hidden;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .vue-flow-editor .vue-flow-editor-canvas .vue-flow-editor-canvas-target {
      position: relative; }
      .vue-flow-editor .vue-flow-editor-canvas .vue-flow-editor-canvas-target .g6-minimap {
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.1); }
  .vue-flow-editor .vue-flow-editor-model {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    -webkit-transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
    transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms; }
    .vue-flow-editor .vue-flow-editor-model:before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.1);
      content: '';
      -webkit-transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
      transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
      -webkit-transition-duration: 500ms;
              transition-duration: 500ms; }
    .vue-flow-editor .vue-flow-editor-model .vue-flow-editor-model-body {
      background-color: white;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      border-left: solid 1px #ddd;
      -webkit-box-shadow: 0 2px 8px #f0f1f2;
              box-shadow: 0 2px 8px #f0f1f2;
      -webkit-transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
      transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
      -webkit-transition-duration: 500ms;
              transition-duration: 500ms; }
      .vue-flow-editor .vue-flow-editor-model .vue-flow-editor-model-body .vue-flow-editor-model-head {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
            -ms-flex-pack: end;
                justify-content: flex-end;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        padding: 0 16px;
        -webkit-box-shadow: 0 2px 8px #f0f1f2;
                box-shadow: 0 2px 8px #f0f1f2; }
        .vue-flow-editor .vue-flow-editor-model .vue-flow-editor-model-body .vue-flow-editor-model-head > i {
          cursor: pointer; }
      .vue-flow-editor .vue-flow-editor-model .vue-flow-editor-model-body .vue-flow-editor-model-content {
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1;
        overflow: auto; }
      .vue-flow-editor .vue-flow-editor-model .vue-flow-editor-model-body .vue-flow-editor-model-foot {
        height: 50px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        border-top: solid 1px #eee;
        -webkit-box-shadow: 0 2px 8px #f0f1f2;
                box-shadow: 0 2px 8px #f0f1f2; }
    .vue-flow-editor .vue-flow-editor-model.vue-flow-editor-transition-enter-active .vue-flow-editor-model-body, .vue-flow-editor .vue-flow-editor-model.vue-flow-editor-transition-leave-active .vue-flow-editor-model-body {
      -webkit-transform: translateX(0);
              transform: translateX(0); }
    .vue-flow-editor .vue-flow-editor-model.vue-flow-editor-transition-enter-active:before, .vue-flow-editor .vue-flow-editor-model.vue-flow-editor-transition-leave-active:before {
      opacity: 1; }
    .vue-flow-editor .vue-flow-editor-model.vue-flow-editor-transition-enter .vue-flow-editor-model-body, .vue-flow-editor .vue-flow-editor-model.vue-flow-editor-transition-leave-to .vue-flow-editor-model-body {
      -webkit-transform: translateX(100%);
              transform: translateX(100%); }
    .vue-flow-editor .vue-flow-editor-model.vue-flow-editor-transition-enter:before, .vue-flow-editor .vue-flow-editor-model.vue-flow-editor-transition-leave-to:before {
      opacity: 0; }
  .vue-flow-editor .vue-flow-editor-preview {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
    transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .vue-flow-editor .vue-flow-editor-preview:before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      background-color: rgba(0, 0, 0, 0.1);
      -webkit-transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
      transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
      -webkit-transition-duration: 500ms;
              transition-duration: 500ms; }
    .vue-flow-editor .vue-flow-editor-preview .vue-flow-editor-preview-body {
      width: 80%;
      height: 80%;
      background-color: white;
      border-radius: 12px;
      position: relative;
      z-index: 1;
      -webkit-transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
      transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
      -webkit-transition-duration: 500ms;
              transition-duration: 500ms; }
      .vue-flow-editor .vue-flow-editor-preview .vue-flow-editor-preview-body .vue-flow-editor-preview-close {
        position: absolute;
        top: -20px;
        right: -20px;
        font-size: 20px;
        background-color: white;
        height: 40px;
        width: 40px;
        border-radius: 20px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-shadow: 0 2px 8px #f0f1f2;
                box-shadow: 0 2px 8px #f0f1f2;
        border: 1px solid #eee;
        color: #999;
        cursor: pointer; }
    .vue-flow-editor .vue-flow-editor-preview.vue-flow-editor-preview-transition-enter-active .vue-flow-editor-preview-body, .vue-flow-editor .vue-flow-editor-preview.vue-flow-editor-preview-transition-leave-active .vue-flow-editor-preview-body {
      -webkit-transform: translateX(0);
              transform: translateX(0); }
    .vue-flow-editor .vue-flow-editor-preview.vue-flow-editor-preview-transition-enter-active:before, .vue-flow-editor .vue-flow-editor-preview.vue-flow-editor-preview-transition-leave-active:before {
      opacity: 1; }
    .vue-flow-editor .vue-flow-editor-preview.vue-flow-editor-preview-transition-enter .vue-flow-editor-preview-body, .vue-flow-editor .vue-flow-editor-preview.vue-flow-editor-preview-transition-leave-to .vue-flow-editor-preview-body {
      -webkit-transform: translateY(-15%);
              transform: translateY(-15%);
      opacity: 0; }
    .vue-flow-editor .vue-flow-editor-preview.vue-flow-editor-preview-transition-enter:before, .vue-flow-editor .vue-flow-editor-preview.vue-flow-editor-preview-transition-leave-to:before {
      opacity: 0; }
