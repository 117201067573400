.el-pagination[data-v-65e70a82] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.ypage[data-v-65e70a82] .el-input__inner {
  font-size: 14px;
}
.ypage[data-v-65e70a82] .number {
  font-size: 14px;
}
.ymiddle[data-v-65e70a82] .el-input__inner {
  font-size: 16px;
}
.ymiddle[data-v-65e70a82] .number {
  font-size: 16px;
}
.ylarge[data-v-65e70a82] .el-input__inner {
  font-size: 18px;
}
.ylarge[data-v-65e70a82] .number {
  font-size: 18px;
}
