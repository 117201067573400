.header-search[data-v-032bd1f0] {
  font-size: 0 !important;
}
.header-search .search-icon[data-v-032bd1f0] {
    cursor: pointer;
    font-size: 18px;
    padding: 6px;
    vertical-align: middle;
    border-radius: 4px;
}
.header-search .search-icon[data-v-032bd1f0]:hover {
      background-color: var(--barColor-dark-2);
      color: var(--barColor-font-active);
}
.header-search .header-search-select[data-v-032bd1f0] {
    font-size: 18px;
    -webkit-transition: width 0.2s;
    transition: width 0.2s;
    width: 0;
    overflow: hidden;
    background: transparent;
    border-radius: 0;
    display: inline-block;
    vertical-align: middle;
}
.header-search .header-search-select[data-v-032bd1f0] .el-input__inner {
      border: 0;
}
.header-search.show .header-search-select[data-v-032bd1f0] {
    width: 210px;
    margin-left: 10px;
}
