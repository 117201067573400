@charset "UTF-8";
.icon-body[data-v-6504d548] {
  width: 550px;
  height: 400px;
  margin: 0 auto;
  overflow-y: auto;
}
#tabs[data-v-6504d548] {
  border-bottom: 1px solid #eee;
}
#tabs li[data-v-6504d548] {
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  border-bottom: 2px solid transparent;
  position: relative;
  z-index: 1;
  margin-bottom: -1px;
  color: #666;
}
#tabs .active[data-v-6504d548] {
  border-bottom-color: #f00;
  color: #222;
}
.tab-container .content[data-v-6504d548] {
  display: none;
}
.icon_lists[data-v-6504d548] {
  width: 100% !important;
  overflow: hidden;
  *zoom: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.icon_lists li[data-v-6504d548] {
  width: 80px;
  margin-bottom: 10px;
  margin-right: 20px;
  text-align: center;
  list-style: none !important;
  cursor: default;
}
.icon_lists li .code-name[data-v-6504d548] {
  line-height: 1.2;
}
.icon_lists .icon[data-v-6504d548] {
  display: block;
  height: 40px;
  line-height: 40px;
  font-size: 30px;
  margin: 10px auto;
  color: #333;
  -webkit-transition: font-size 0.25s linear, width 0.25s linear;
  transition: font-size 0.25s linear, width 0.25s linear;
}
.icon_lists .icon[data-v-6504d548]:hover {
  font-size: 40px;
}
.icon_lists .svg-icon[data-v-6504d548] {
  /* 通过设置 font-size 来改变图标大小 */
  width: 1em;
  /* 图标和文字相邻时，垂直对齐 */
  vertical-align: -0.15em;
  /* 通过设置 color 来改变 SVG 的颜色/fill */
  fill: currentColor;
  /* path 和 stroke 溢出 viewBox 部分在 IE 下会显示
      normalize.css 中也包含这行 */
  overflow: hidden;
}
.icon_lists li .name[data-v-6504d548],
.icon_lists li .code-name[data-v-6504d548] {
  color: #666;
}
